<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr v-if="$store.state.Auth.activeRoleId === 1">
                                <b-th style="width:20%">{{ $t('globalTrans.organization') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? prayGradeSetup.org_bn : prayGradeSetup.org_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.pay_grade_from') }}</b-th>
                                <b-td style="width:30%">{{ $n(prayGradeSetup.pay_grade_from )}}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.pay_grade_to') }}</b-th>
                                <b-td style="width:30%">{{ $n(prayGradeSetup.pay_grade_to ) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.amount') }}</b-th>
                                <b-td style="width:30%">{{ $n(prayGradeSetup.amount) }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.effective_date_from') }}</b-th>
                                <b-td style="width:30%">{{ prayGradeSetup.date_from | dateFormat }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.effective_date_to') }}</b-th>
                                <b-td style="width:30%">{{ prayGradeSetup.date_to | dateFormat }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.remarks') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? prayGradeSetup.remarks_bn : prayGradeSetup.remarks }}</b-td>
                            </b-tr>
                            <!-- <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.remarks') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? prayGradeSetup.remarks_bn : prayGradeSetup.remarks }}</b-td>
                            </b-tr> -->
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    if (this.item) {
      this.prayGradeSetup = this.item
    }
  },
  data () {
    return {
      prayGradeSetup: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
