<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form"  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <ValidationProvider v-if="$store.state.Auth.activeRoleId === 1" name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="gradeSetup.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="gradeSetup.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Pay Grade From"  vid="pay_grade_from" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pay_grade_from"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('elearning_config.pay_grade_from')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="gradeSetup.pay_grade_from"
                              :options="gradeList"
                              id="pay_grade_from"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Pay Grade To" vid="pay_grade_to" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="pay_grade_to"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('elearning_config.pay_grade_to')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="gradeSetup.pay_grade_to"
                              :options="payGradeToList"
                              id="pay_grade_to"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Amount" vid="amount" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="amount"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                              {{$t('elearning_config.amount')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              type="number"
                              id="amount"
                              v-model="gradeSetup.amount"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Effective Date From" vid="date_from" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="date_from"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('elearning_config.effective_date_from')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="gradeSetup.date_from"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Effective Date To" vid="date_to" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="date_to"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('elearning_config.effective_date_to')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  class="fromDate"
                                  v-model="gradeSetup.date_to"
                                  :placeholder="$t('globalTrans.select_date')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Proggapon " vid="attachment">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="attachment"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.attachment')}}
                                  </template>
                                  <b-form-file
                                      id="attachment"
                                      v-model="gradeSetup.attachment_file"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="remarks"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.remarks_en')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="remarks"
                                      v-model="gradeSetup.remarks"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="remarks_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('elearning_config.remarks_bn')}}
                                  </template>
                                  <b-form-textarea
                                      rows="2"
                                      id="remarks_bn"
                                      v-model="gradeSetup.remarks_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-textarea>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                          <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                          </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { payGradeSetupStore, payGradeSetupUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getgradeSetup()
      this.gradeSetup = tmp
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.gradeSetup.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      gradeSetup: {
        id: '',
        org_id: 0,
        training_type_id: 0,
        pay_grade_from: 0,
        pay_grade_to: 0,
        amount: '',
        date_to: '',
        date_from: '',
        attachment_file: [],
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      payGradeToList: [],
      trainingTypeList: []
    }
  },
  computed: {
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // },
    gradeList: function () {
      return this.$store.state.commonObj.gradeList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
      'gradeSetup.pay_grade_from': function (newValue) {
          this.payGradeToList = this.getpayGradeTo(newValue)
       },
      'gradeSetup.org_id': function (newValue) {
         this.trainingTypeList = this.getTypeList(newValue)
    }
  },
  methods: {
    getgradeSetup () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
      return type
    },
    getpayGradeTo (gradeId) {
       const grade = this.gradeList
       if (gradeId) {
         return grade.filter(item => item.value >= gradeId)
       }
       return grade
    },
    async createData () {
      this.loading = true
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.gradeSetup).map(key => {
          if (key === 'attachment_file') {
              formData.append('attachment', this.gradeSetup.attachment_file)
          } else {
              formData.append(key, this.gradeSetup[key])
          }
      })
      if (this.gradeSetup.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${payGradeSetupUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, payGradeSetupStore, formData, config)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
